@font-face {
    font-family: 'Jamesfajardo';
    src: url('../../fonts/frontend/JamesFajardo.ttf');
}
* {
    outline: none;
}
body {
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    color: #000;
}
a,button{
    transition:0.5s all;
    -webkit-transition:0.5s all;
    -moz-transition:0.5s all;
    -ms-transition:0.5s all;
    -o-transition:0.5s all;
}
a{
    text-decoration:none;
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    color: #000;
}
a:focus,a:hover,a:active{
    outline: 0;
    box-shadow: 0;
}
a:focus, a:hover{
    text-decoration: none;
    color: #000;
}
.h1, .h2, .h3, h1, h2, h3, .h4, .h4, .h5, h5, h6, h6 {
    margin-bottom: 0;
    margin-top: 0;
}
h1{
    color: #000000;
    font-family: "Courgette",cursive;
    font-size: 45px;
    text-transform: capitalize;
}
h2{
    color: #000000;
    font-family: "Courgette",cursive;
    font-size: 30px;
}
h2 a{
    color: #000000;
    font-family: "Courgette",cursive;
    font-size: 30px;
}
h3{
    color: #000000;
    font-family: "Courgette",cursive;
    font-size: 24px;
}
h3 a{
    color: #000000;
    font-family: "Courgette",cursive;
    font-size: 24px;
    text-decoration: none;
}
a{
    text-decoration: none;
}
a:hover{
    color: #000;
    text-decoration: none;
}

.xp-header ul{
    margin: 0;
    padding: 0;
}
.xp-header ul li{
    list-style: none;
}
p{
    margin: 0;
}
p a,p a:hover {
    color: #836d44;
}
input:focus,
select:focus,
textarea:focus{
    outline: none;
}
.clid-pernt {
    display: table;
    height: 100%;
    width: 100%;
}
.child {
    display: table-cell;
    vertical-align: middle;
}
.wrapper{
    background: url(../../images/kaart-bg.svg);
    background-repeat: no-repeat;
    height: 100vh;
    background-size: cover;
    background-position: center;
    position: relative;
}
.khojasse-main{
    padding-top: 30px;
}
.khojasse-header-top{
    padding: 0 12%;
    position: absolute;
    top: 5%;
    width: 100%;
}
.khojasse-header-top a {
    background: rgba(0, 0, 0, 0) url("../../images/wie-zijn-wij.svg") no-repeat scroll center center / cover ;
    color: #ffffff;
    display: inline-block;
    font-family: "Courgette",cursive;
    font-size: 20px;
    height: 98px;
    margin: 40px 0 0;
    padding: 36px 0;
    text-align: center;
    text-decoration: none;
    transform: rotate(-6deg);
    width: 176px;
}
.khojasse-header-top a span{
    display: inline-block;
    transform: rotate(-15deg);
}
.khojasse-header-top a:last-child{
    background: rgba(0, 0, 0, 0) url("../../images/Boek-ons-nu.svg") no-repeat scroll 0 0;
    float: right;
    height: 76px;
    margin: 0;
    padding: 24px 0;
    transform: none;
    width: 176px;

}
.khojasse-header-mid{
    left: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
}
.khojasse-header-mid .logo {
    width: 180px;
    display: inline-block;
}
.khojasse-header-mid .logo img{
    width: 100%;
}
.khojasse-header-mid .home-link{
    margin-top: 10px;
}
.khojasse-header-mid .home-link a{
    background: rgba(0, 0, 0, 0) url("../../images/home.svg") no-repeat scroll center center / cover ;
    color: #ffffff;
    display: inline-block;
    font-family: "Courgette",cursive;
    font-size: 20px;
    height: 62px;
    margin-right: 0;
    padding: 17px 0;
    text-decoration: none;
    text-transform: capitalize;
    width: 140px;
}
.khojasse-header-last{
    bottom: 5%;
    padding: 0 12%;
    position: absolute;
    text-align: center;
    width: 100%;
}
.khojasse-header-last a:first-child {
    background: rgba(0, 0, 0, 0) url("../../images/workshops.svg") no-repeat scroll center center / cover ;
    color: #ffffff;
    display: inline-block;
    float: left;
    font-family: "Courgette",cursive;
    font-size: 20px;
    height: 72px;
    margin-right: 66px;
    padding: 21px 0;
    text-decoration: none;
    text-transform: capitalize;
    width: 140px;
}
.khojasse-header-last a:first-child span{
    transform: rotate(7deg);
    display: inline-block;
}
.khojasse-header-last a:nth-child(2) {
    background: rgba(0, 0, 0, 0) url("../../images/Boek-ons-nu.svg") no-repeat scroll center center / cover ;
    color: #ffffff;
    display: block;
    float: left;
    font-family: "Courgette",cursive;
    font-size: 20px;
    height: 57px;
    left: 0;
    margin: 0 auto;
    padding: 16px 0;
    position: absolute;
    right: 60px;
    text-decoration: none;
    text-transform: capitalize;
    width: 140px;
}
.khojasse-header-last a:last-child{
    float: right;
    background: rgba(0, 0, 0, 0) url("../../images/Boek-ons-nu.svg") no-repeat scroll center center / cover ;
    color: #ffffff;
    display: inline-block;
    font-family: "Courgette",cursive;
    font-size: 20px;
    height: 57px;
    padding: 16px 0;
    text-decoration: none;
    text-transform: capitalize;
    width: 140px;
}
/*================================================
                kj Grijs 
================================================*/
.xp-wrapper{
    background: url(../../images/kaart-bg.svg);
    background-repeat: no-repeat;
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    position: relative;
}
.xp-header{
    background-color: #fff;
    padding: 5px 0;
    -webkit-box-shadow: 0px 6px 9px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 6px 9px -6px rgba(0,0,0,0.75);
    box-shadow: 0px 6px 9px -6px rgba(0,0,0,0.75);
}
.xp-logo{
    width: 90px;
}
.xp-logo img{
    width: 100%;
}
.xp-navigation{
    padding: 19px 0;
}
.xp-navigation .navbar-default {
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
}
.xp-navigation .navbar {
    border: medium none;
    border-radius: 0;
    margin-bottom: 0;
    min-height: inherit;
}
.xp-navigation .navbar-collapse{
    padding: 0;
}
.xp-navigation  .navbar-nav {
    float: none;
    margin: 0;
}
.xp-navigation .nav > li > a{
    color: #000000;
    font-family: "Courgette",cursive;
    font-size: 24px;
    padding: 15px 23px;
}
.xp-navigation .nav > li > a:hover{
    color: #f8daa4;
}
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:focus, .navbar-default .navbar-nav > .active > a:hover{
    background-color: transparent;
    color: #f8daa4;
}
.xp-main-contant{
    padding: 150px 0 50px;
}
.xp-geplande-innar .nav-tabs {
    border-bottom: medium none;
    margin-bottom: 30px;
}
.xp-geplande-innar .nav-tabs > li > a {
    border: medium none;
    line-height: none;
    margin-right: 0;
    background-color: #f8daa4;
    color: #000000;
    display: inline-block;
    font-family: "Courgette",cursive;
    font-size: 20px;
    padding: 7px 30px;
    margin-right: 20px;
    text-decoration: none;
    text-transform: capitalize;
    -webkit-box-shadow: 0px -1px 10px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px -1px 10px 0px rgba(0,0,0,0.3);
    box-shadow: 0px -1px 10px 0px rgba(0,0,0,0.3);
}
.xp-geplande-innar .nav-tabs > li > a:hover{
    background-color: #fff;
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover{
    border: none;
    background-color: #fff;
}
.table-responsive{
    -webkit-box-shadow: 0px -1px 10px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px -1px 10px 0px rgba(0,0,0,0.3);
    box-shadow: 0px -1px 10px 0px rgba(0,0,0,0.3);
}
table{
    width: 100%;
    background-color: #fff;
    padding-bottom: 30px;
}
table thead tr{
    border-bottom: 2px solid #000;
}
table thead tr th{
    font-family: "Courgette",cursive;
    font-size: 20px;
    color: #000;
    padding: 10px 40px;
}
table thead tr th:last-child{
    text-align: right;
}
table tbody tr td{
    font-family: "Arial",cursive;
    font-size: 20px;
    color: #000;
    padding: 8px 40px;
    margin: 5px 0;
}
table tbody tr td a{
    font-size: 20px;
}
table tbody tr td a:hover{
    color: #f8daa4;
}
table tbody tr td:last-child a{
    font-family: "Arial",cursive;
    color: #000;
    font-size: 20px;
    padding: 8px 15px;
    display: inline-block;
    background-color: #f8daa4;
    margin: 8px 0;
}
table tbody tr td:last-child a:hover{
    background-color: #fff;
}
table tbody tr td:last-child{
    text-align: right;
    padding: 0;
}
table tbody tr td:last-child a{
    margin-right: 25px
}
table tbody tr:nth-child(even) {background: #fff}
table tbody tr:nth-child(odd) {background: #eaeaea}
/*================================================
                kj Grijs V2
================================================*/
.kj-grijs-main{
    min-height: 100vh;
}
.xp-headre-v2 .col-md-2{
    text-align: center;
}
.xp-headre-v2 .xp-logo {
    width: 120px;
    display: inline-block;
}
.xp-headre-v2 .navigation {
    padding: 42px 0;
}
.navigation ul li{
    display: inline-block;
}
.navigation ul li a{
    color: #000000;
    font-family: "Courgette",cursive;
    font-size: 24px;
    padding: 0 20px;
    text-decoration: none;
}
.navigation ul li:first-child a{
    padding-left: 0;
}
.navigation ul li:last-child a{
    padding-right: 0;
}
.xp-footer{
    background-color: #fff;
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 0;
}
.xp-footer p{
    font-size: 16px;
    padding: 20px 0
}
.xp-footer .webclix{
    position: absolute;
    right: 10px;
    top: 10px;
}
.xp-footer .webclix a{
    font-size: 1.5em;
    font-family: 'Jamesfajardo';
    color: #7b7c7c;
}
.xp-footer .webclix a span{
    margin-top: 5px;
    margin-right: 10px;
    display: inline-block;
}
.xp-footer .webclix .webclix-logo{
    width: 33px;
    display: inline-block;
    margin-top: -5px;
}
/*================================================
           Geplande Workshop
================================================*/
.xp-geplande-workshop-left{
    background-color: #fff;
    -webkit-box-shadow: 0px -1px 10px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px -1px 10px 0px rgba(0,0,0,0.3);
    box-shadow: 0px -1px 10px 0px rgba(0,0,0,0.3);
    padding: 30px 30px 0;
    min-height: 672px;
}
.xp-geplande-workshop-left h2,
.xp-geplande-workshop-right h2{
    text-align: center;
    padding-bottom: 30px;
}
.xp-geplande-workshop-left .text-box{
    margin-bottom: 20px;
}
.xp-geplande-workshop-left .iimage-holder{
    width: 100%;
    height: 160px;
    background-color: #d1d2d3;
    margin-bottom: 20px;
}
.workshop-contant{
    padding-bottom: 30px;
}
.xp-geplande-workshop-right{
    background-color: #fff;
    -webkit-box-shadow: 0px -1px 10px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px -1px 10px 0px rgba(0,0,0,0.3);
    box-shadow: 0px -1px 10px 0px rgba(0,0,0,0.3);
    padding: 30px 30px 10px;
    width: 75%;
    float: right;
    min-height: 672px;
}
form .form-group label{
    font-size: 18px;
    font-weight: normal;
    text-transform: capitalize;
    display: block;
}
form .form-group input[type="text"]{
    border: none;
    background-color: #e8e8e9;
    border-radius: 0;
    width: 100%;
    padding-left: 10px;
    height: 42px;
}
form .form-group select{
    border: none;
    border-radius: 0;
    width: 100%;
    padding-left: 10px;
    height: 42px;
    background: #e8e8e9 url(../../images/select-arrow.png) no-repeat scroll 95% center / 15px auto;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
form .form-group textarea{
    background-color: #e8e8e9;
    border: medium none;
    border-radius: 0;
    height: 108px;
    margin-top: 15px;
    padding: 10px;
    width: 100%;
}
form .form-group input[type="submit"]{
    border: none;
    background-color: #f8daa4;
    border-radius: 0;
    width: 100%;
    padding-left: 10px;
    padding: 13px 0;
    color: #000;
    -webkit-box-shadow: 0px -1px 10px 4px rgba(232,232,233,0.75);
    -moz-box-shadow: 0px -1px 10px 4px rgba(232,232,233,0.75);
    box-shadow: 0px -1px 10px 4px rgba(232,232,233,0.75);
    transition:  all 0.5s ease-in-out;
}
form .form-group input[type="submit"]:hover{
    background-color: #fff;
    color: #000;
    transition:  all 0.5s ease-in-out;
}
.xp-geplande-workshop-right p a{
    color: #836d44;
}
/*================================================
          Blog
================================================*/
.xp-blog,
.xp-blog-detail,
.xp-contact-us,
.xp-geplande,
.xp-geplande-workshop,
.xp-gallery{
    margin-bottom: 112px;
}
.xp-blog-inner{
    box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.75);
}
.xp-blog .col-md-6{
    background-color: #fff;
    padding-left: 0;
}
.xp-blog-left{
    text-align: center;
}
.xp-blog-left .image-holder img{
    height: 83vh;
    object-fit: cover;
    width: 100%;
}
.xp-blog-left .image-holder{
    position: relative;
}
.xp-blog-left .image-holder::after{
    background-color: rgba(0, 0, 0, 0.2);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}
.xp-blog-left .text-box{
    bottom: 40px;
    padding: 0 70px;
    position: absolute;
    z-index: 1;
    width: 100%;
}
.xp-blog-left .text-box h2{
    color: #fff;
    padding-bottom: 10px;
}
.xp-blog-left .text-box p{
    color: #fff;
    padding-bottom: 10px;
}
.xp-blog-left .text-box ul li{
    display: inline-block;
}
.xp-blog-left .text-box ul li a{
    color: #ffffff;
    font-size: 18px;
    padding: 0 10px;
}
.xp-blog-right{
    background-color: #ffffff;
    height: 83vh;
    overflow-y: scroll;
    padding: 0 20px;
    border-bottom: 20px solid #fff;
    border-top: 20px solid #fff;
}
.xp-blog-right .xp-blog-colum{
    margin-bottom: 40px;
}
.xp-blog-right .xp-blog-colum span{
    display: block;
    padding-bottom: 5px;
    color: #836d44;
}
.xp-blog-right .xp-blog-colum h3{
    padding-bottom: 10px;
}
.xp-blog-right .xp-blog-colum p{
    font-size: 14px;
}
/*================================================
           Blog Detail 
================================================*/
.xp-blog-detail-main{
    padding-top: 0;
}
.blog-banner{
    background: url(../../images/blog-detail-img.jpg) no-repeat;
    background-size: cover;
    background-position: 100%;
    height: 445px;
}
.blog-detail-inner{
    background-color: #fff;
    padding: 20px 30px 50px;
    box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.75);
}
.xp-geplande .slimScrollDiv {
    box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.75);
}
.blog-detail-inner-trip span{
    color: #836d44;
    display: block;
    padding-bottom: 5px;
}
.blog-detail-inner-trip h1{
    padding-bottom: 20px;
}
.blog-detail-contant{
    margin-bottom: 20px;
}
.blog-detail-contant p{
    line-height: 30px;
    color: #888888;
}
.blog-detail-quets{
    background-color: #ebebeb;
    padding: 20px 30px;
    margin-bottom: 20px;
    border-left: 5px solid #836d44;
}
.blog-detail-quets p {
    letter-spacing: 2px;
}
.blog-recent h2{
    text-align: left;
    text-transform: capitalize;
    padding-bottom: 15px;
}
.blo-recent-inner .image-holder{
    position: relative;
    cursor: pointer;
}
.blo-recent-inner .image-holder img{
    width: 100%;
}
.blo-recent-inner .text-box{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    text-align: center;  
    transition: all 0.5s ease-in-out;
}
.blo-recent-inner .text-box:after {
    background-color: rgba(0, 0, 0, 0.5);
    content: "";
    height: 100%;
    left: 0;
    opacity: 1;
    position: absolute;
    top: 0;
    transition: all 0.5s ease 0s;
    width: 100%;
}
.blo-recent-inner .text-box h3,
.blo-recent-inner .text-box span{
    color: #ffffff;
    position: relative;
    z-index: 1;
}
.blo-recent-inner .image-holder:hover .text-box{
    opacity: 0;
    transition: all 0.5s ease-in-out;
}
/*================================================
           Contant Us
================================================*/
.xp-contant-adress{
    background-color: #f2f2f2;
    box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.75);
}
.xp-contant-adress .map-contant{
    float: left;
    width: 60%;
}
.xp-contant-locatrion{
    float: right;
    padding: 30px 50px;
    width: 40%;
}
.xp-contant-locatrion-colum {
    margin-bottom: 30px;
}
.xp-contant-locatrion-colum h2{
    text-transform: capitalize;
    text-align: left;
    padding-bottom: 15px;
}
.xp-contant-locatrion-colum p{
    color: #888888;
}
.xp-contant-locatrion-colum a{
    color: #888888;
    display: block;
    padding-bottom: 5px;
}
.xp-contant-locatrion-colum a:hover{
    color: #f8daa4;
}
.xp-contant-locatrion-colum:last-child{
    margin-bottom: 0;
}
.xp-contact-form{
    background-color: #fff;
    margin-top: 50px;
    padding: 30px;
    box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.75);
}
.xp-contact-form h2{
    text-align: left;
    text-transform: capitalize;
    padding-bottom: 30px;
}
.xp-contact-form form .form-group textarea{
    height: 200px;
}
.xp-contact-form form .form-group input[type="submit"]{
    width: auto;
    padding: 13px 30px;
}
/*================================================
           Who We Are
================================================*/
.xp-who-we-are{
    background-color: #fff;
    min-height: 617px;
    padding: 30px;
    box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.75);
}
.xp-who-we-are .xp-who-we-colum {
    /*margin-bottom: 30px;*/
}
.xp-who-we-are .xp-who-we-colum p {
    margin-bottom: 15px;
}
.xp-who-we-are .xp-who-we-colum:last-child{
    margin-bottom: 0;
}
.xp-who-we-are .xp-who-we-colum h2{
    text-align: left;
    padding-bottom: 10px;
}
.xp-blog .slimScrollDiv .slimScrollBar{
    right: 0 !important;
}
.slimScrollDiv .slimScrollBar{
    right: 10px !important;
    opacity: 1 !important;
    background-color: #f8daa4 !important;
    margin: 10px 0 !important;
}
/*================================================
           Gallery
================================================*/
.gallery-inner{
    margin-bottom: 30px;
}
.gallery-inner .image-holder{
    position: relative;
}
.gallery-inner .image-holder img{
    width: 100%;
}
.gallery-inner .text-box{
    background-color: rgba(0, 0, 0, 0.3);
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: all 0.5s ease 0s;
    width: 100%;
    text-align: center;
}
.gallery-inner .text-box p{
    color: #fff;
    text-transform: uppercase;
    font-size: 18px;
}
.gallery-inner .image-holder:hover .text-box{
    opacity: 1;
    transition: all 0.5s ease 0s;
}
.demo-gallery{
    background-color: #fff;
    padding: 30px 30px 0;
    box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.75);
}
.lg-backdrop {
    background-color: rgba(0, 0, 0, 0.8);
}
.demo-gallery > ul {
    margin-bottom: 0;
}
.demo-gallery > ul > li{
    margin-bottom: 30px;
}
.demo-gallery > ul > li a {
    display: block;
    overflow: hidden;
    position: relative;
}
.demo-gallery > ul > li a > img {
    -webkit-transition: -webkit-transform 0.15s ease 0s;
    -moz-transition: -moz-transform 0.15s ease 0s;
    -o-transition: -o-transform 0.15s ease 0s;
    transition: transform 0.15s ease 0s;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    height: 100%;
    width: 100%;
}
.demo-gallery > ul > li a:hover > img {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
}
.demo-gallery > ul > li a:hover .demo-gallery-poster > img {
    opacity: 1;
}
#lg-download{
    display: none;
}
.demo-gallery > ul > li a > img {
    height: 165px;
    object-fit: cover;
    transform: scale3d(1, 1, 1);
    transition: transform 0.15s ease 0s;
    width: 100%;
}
/*================================================
           Tern Condition
================================================*/
.xp-tearn-condition{
    background-color: #fff;
    box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.75);
    padding: 20px 30px;
    min-height: 40vh;
}
.xp-tearn-condition h2{
    padding-bottom: 20px;
}
.xp-tearn-condition p{
    padding-bottom: 20px;
}
.xp-tearn-condition ul li{
    list-style: outside none disc;
    margin-bottom: 10px;
    margin-left: 15px;
}
img[usemap] {
    border: none;
    object-fit: cover;
    max-width: 100%;
    width: 100%;
}
.mobail-home{
    display: none;
}
.xp-geplande-workshop-right.arch {
    padding: 30px;
}

.dashboard {
    position: fixed;
    top: 20px;
    right: 20px;
    font-size: 1.5em;
}
